import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/admin/login',
      component: () => import('@/pages/login/Index'),
      name: 'login'
    },
    {
      path: '/admin/dashboard',
      component: () => import('@/pages/dashboard/Index')
    },
    {
      path: '/admin/workouts',
      component: () => import('@/pages/workouts/Index')
    },
    // {
    //   path: '/admin/workouts/create',
    //   component: () => import('@/pages/workouts/Workout')
    // },
    {
      path: '/admin/workouts/:id',
      component: () => import('@/pages/workouts/Workout')
    },
    {
      path: '/admin/workouts/:id/cancel',
      component: () => import('@/pages/workouts/Cancel')
    },
    {
      path: '/admin/templates',
      component: () => import('@/pages/templates/Index')
    },
    {
      path: '/admin/templates/create',
      component: () => import('@/pages/templates/Template')
    },
    {
      path: '/admin/templates/:id',
      component: () => import('@/pages/templates/Template')
    },
    {
      path: '/admin/locations',
      component: () => import('@/pages/locations/Index')
    },
    {
      path: '/admin/locations/create',
      component: () => import('@/pages/locations/Location')
    },
    {
      path: '/admin/locations/:id',
      component: () => import('@/pages/locations/Location')
    },
    {
      path: '/admin/types',
      component: () => import('@/pages/types/Index')
    },
    {
      path: '/admin/sponsors',
      component: () => import('@/pages/sponsors/Index')
    },
    {
      path: '/admin/banners',
      component: () => import('@/pages/banners/Index')
    },
    {
      path: '/admin/banners/create',
      component: () => import('@/pages/banners/Banner')
    },
    {
      path: '/admin/banners/:id',
      component: () => import('@/pages/banners/Banner')
    },
    {
      path: '/admin/view-stats',
      component: () => import('@/pages/stats/Index')
    },
    {
      path: '/',
      redirect: '/admin/dashboard'
    },
    {
      path: '/admin',
      redirect: '/admin/dashboard'
    },
    // Keep this last
    {
      path: '*',
      component: () => import('@/pages/errors/404')
    }
  ]
})
