<template>
  <div id="app">
    <Nav />
    <router-view></router-view>
  </div>
</template>

<script>
import Nav from '@/components/Nav'

export default {
  name: 'app',
  components: { Nav },
  data () {
    return {
      loaded: false
    }
  },
  methods: {
    async checkAuth () {
      const token = localStorage.getItem('token')
      if (!token) {
        throw new Error('no_token')
      }
      this.$root.token = token
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${this.$root.token}`
      const method = 'post'
      const url = `${this.$config.apiUrl}/token/validate`
      await this.axios({
        method,
        url,
        withCredentials: true
      })
    },
    async loadAsyncData() {
      const [
        { data: cities },
        { data: categories },
        { data: locations }
      ] = await Promise.all([
        this.axios({ url: `${this.$config.apiUrl}/cities` }),
        this.axios({ url: `${this.$config.apiUrl}/categories` }),
        this.axios({ url: `${this.$config.apiUrl}/locations` })
      ])
      this.$root.cities = cities
      this.$root.categories = categories
      this.$root.locations = locations
    }
  },
  async created () {
    try {
      await this.checkAuth()
      this.$root.loggedIn = true
      this.loadAsyncData()
    } catch (error) {
      this.$router.replace('/admin/login')
    }
    this.loaded = true
  }
}
</script>

<style>
.list-image {
  width: 100%;
  max-height: 100%;
}
.remove-icon-wrapper {
  position:absolute;
  right:-.75rem;
  top:-.75rem;
  z-index:2;
  cursor:pointer;
}
</style>
