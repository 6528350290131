import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAMap from 'vue-amap'

/* eslint-disable import/no-extraneous-dependencies */
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'vue-datetime/dist/vue-datetime.css'

import App from './App'
import router from './router'

Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(VueAMap)

const aMapKey = 'f556b7e05889a147f83c206580070f21'
VueAMap.initAMapApiLoader({
  key: aMapKey,
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.MapType'],
  v: '1.4.6'
})

Vue.config.productionTip = false

/* eslint-disable prefer-template */
Vue.prototype.$config = {
  apiUrl: `//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/api/v2`,
  cdnUrl: 'https://cdn.ffglobalcommunity.com/images',
  aMapKey
}

const vue = new Vue({
  router,
  data: {
    loggedIn: false,
    token: '',
    cities: [],
    categories: [],
    locations: []
  },
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !vue.loggedIn) {
    next({
      path: '/admin/login'
    })
  } else {
    next()
  }
})
