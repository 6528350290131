<template>
  <b-navbar
    id="nav"
    type="dark"
    variant="dark"
    toggleable="md"
    sticky
  >
    <b-navbar-brand to="/admin">
      <b-img
        src="@/assets/fitfam-nav-logo-80x80.png"
        style="width:40px;height:40px;"
        alt="FitFam"
      ></b-img>
    </b-navbar-brand>
    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
    <b-collapse
      id="nav_collapse"
      is-nav
    >
      <b-navbar-nav v-if="this.$root.loggedIn">
        <b-nav-item
          v-for="item in menuItems"
          :key="item.name"
          :to="item.url"
        >{{ item.name }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item
          v-if="!this.$root.loggedIn"
          :to="'/admin/login'"
        >Login</b-nav-item>
        <b-nav-item
          v-else
          @click="logoutUser"
        >Logout</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'Nav',
  data () {
    return {
      menuItems: [
        {
          url: '/admin/dashboard',
          name: 'Dashboard'
        },
        {
          url: '/admin/workouts',
          name: 'Workouts'
        },
        {
          url: '/admin/templates',
          name: 'Templates'
        },
        {
          url: '/admin/locations',
          name: 'Locations'
        },
        {
          url: '/admin/types',
          name: 'Types'
        },
        {
          url: '/admin/sponsors',
          name: 'Sponsors'
        },
        {
          url: '/admin/banners',
          name: 'Banners'
        },
        {
          url: '/admin/view-stats',
          name: 'Statistics'
        }
        // {
        //     url: '/admin/users',
        //     name: 'Users'
        // },
        // {
        //     url: '/admin/settings',
        //     name: 'Settings'
        // }
      ]
    }
  },
  methods: {
    async logoutUser() {
      localStorage.removeItem('token')
      this.axios.defaults.headers.common['Authorization'] = null
      this.$root.token = null
      this.$root.loggedIn = false
      this.$router.replace('/admin/login')
    }
  }
}
</script>

<style>
</style>
